<template>
  <base-card :class="'transparent ' + baseCardClasses">
    <v-card-title v-if="showTitleAndSearch">
      <v-icon v-if="icon" class="mr-2">
        {{ icon }}
      </v-icon>
      {{ title }}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      >
      </v-text-field>
    </v-card-title>
    <v-card-text :class="cardTextClasses">
      <v-data-table
        :dense="isDense"
        :hide-default-footer="!showFooter"
        :value="defaultSelectedRows"
        :show-select="showSelect"
        :single-select="singleSelect"
        @item-selected="selectedItem"
        class="transparent"
        :item-key="itemKey"
        :items="items"
        :items-per-page="pageSize"
        :page="pageNumber"
        @pagination="updatedPagination"
        :loading="isLoading"
        :headers="getHeaders"
        :server-items-length="totalRecordCount"
        @click:row="rowClicked"
      >
        <!-- <template :slot="'item.' + col.name" v-for="(col, index) in headers">
hello
        </template> -->
        <template
          v-for="slot in Object.keys($scopedSlots)"
          :slot="slot"
          slot-scope="scope"
          ><slot :name="slot" v-bind="scope"
        /></template>
      </v-data-table>
    </v-card-text>
  </base-card>
</template>

<script>
export default {
  props: {
    baseCardClasses: {
      type: String,
      default: ''
    },
    cardTextClasses: {
      type: String,
      default: ''
    },
    isDense: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showTitleAndSearch: {
      type: Boolean,
      default: true,
    },
    defaultSelectedRows: {
      type: Array,
      default: () => [],
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    singleSelect: {
      type: Boolean,
      default: true,
    },
    itemKey: {
      type: String,
      default: "id",
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    totalRecordCount: {
      type: Number,
      default: 0,
    },
    tableId: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "Table Title",
    },
    items: {
      type: Array,
      default: () => [],
    },
    hasLoaded: {
      type: Boolean,
      default: false,
    },
    pageSize: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    getHeaders() {
      this.headers.forEach((x) => {
        x.sortable = false;
        if (!x.align) {
          x.align = "start";
        }
      });

      return this.headers;
    },
  },
  watch: {
    search(newVal, oldVal) {
      this.isLoading = true;
      clearTimeout(this.debounceId);

      this.debounceId = setTimeout(() => {
        this.$emit("searching", newVal);
      }, 300);
    },
  },
  methods: {
    selectedItem(item) {
      this.$emit("item-selected", item.item);
    },
    rowClicked(row) {
      this.$emit("row-clicked", row);
    },
    updatedPagination(paginationObject) {
      this.pageNumber = paginationObject.page;

      if (this.hasLoaded) {
        this.$emit("pagination-changed", paginationObject);
      }
    },
  },
  data: function () {
    return {
      debounceId: "",
      search: "",
      pageNumber: 1,
    };
  },
};
</script>

<style></style>
