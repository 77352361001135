<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <trev-cool-table-base
            title="Microsoft Products"
            icon="mdi-microsoft-windows"
            :isLoading="isLoading"
            :items="items"
            :totalRecordCount="totalCount"
            :headers="headers"
            tableKey="companyId"
            :defaultPageSize="100"
            :isExpandable="true"
            :showSearch="false"
            @get-data="getData"
          >
            <template #topBarButton>
              <v-btn
                color="success"
                small
                @click="showCreateAllInvoices"
                :loading="isLoadingAllInvoicingButton"
              >
                <v-icon class="mr-2"> mdi-chart-box-plus-outline </v-icon>
                Create All Invoices
              </v-btn>
            </template>
            <template #item.companyName="{ item }">
              <v-btn
                text
                :to="{
                  name: 'Company Dashboard',
                  params: { companyId: item.companyId },
                }"
                small
              >
                <v-icon class="mr-2"> mdi-office-building-outline </v-icon>
                {{ item.companyName }}
              </v-btn>
            </template>
            <template #item.alerts="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-on="on"
                    v-bind="attrs"
                    color="danger"
                    v-show="item.totalRetailPrice < item.totalRecommendedPrice"
                  >
                    mdi-alert-outline
                  </v-icon>
                </template>
                <span> Our sell price is lower than the MSRP </span>
              </v-tooltip>
            </template>
            <template #item.totalRecommendedPrice="{ item }">
              <v-chip color="info"> £{{ item.totalRecommendedPrice.toFixed(2) }} </v-chip>
            </template>
            <template #item.totalCostPrice="{ item }">
              <v-chip color="primary"> £{{ item.totalCostPrice.toFixed(2) }} </v-chip>
            </template>
            <template #item.totalRetailPrice="{ item }">
              <v-chip color="success"> £{{ item.totalRetailPrice.toFixed(2) }} </v-chip>
            </template>
            <template #item.profit="{ item }">
              <v-chip color="purple" class="white--text"> £{{ item.profit.toFixed(2) }} </v-chip>
            </template>
            <template #item.actions="{ item }">
              <v-tooltip v-if="$store.getters.hasScope('Product.Modify')" top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    @click="showPricingModal(item)"
                    color="primary"
                    small
                    class="mr-2"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon> mdi-update </v-icon>
                  </v-btn>
                </template>
                <span> Update Pricing </span>
              </v-tooltip>
              <v-tooltip top v-if="$store.getters.hasScope('Xero.Create')">
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    small
                    class="mr-2"
                    v-on="on"
                    v-bind="attrs"
                    :disabled="!item.xeroContactId || !item.xeroTenantId"
                    @click="showInvoiceModal(item)"
                  >
                    <v-icon> mdi-chart-box-plus-outline </v-icon>
                  </v-btn>
                </template>
                <span> Create Invoice </span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <h6 class="mt-4">Products (Microsoft)</h6>
                <trev-simple-table
                  baseCardClasses="p-0"
                  cardTextClasses="py-0 px-0"
                  :headers="productHeaders"
                  :showTitleAndSearch="false"
                  :items="item.products"
                  :pageSize="1000"
                  :showFooter="false"
                  :isDense="true"
                  :isLoading="false"
                  :hasLoaded="true"
                  :totalRecordCount="1000"
                >
                  <template #item.costPrice="{ item }">
                    <v-chip color="info" small> £{{ item.costPrice.toFixed(2) }} </v-chip>
                  </template>
                  <template #item.recommendedPrice="{ item }">
                    <v-chip color="primary" small>
                      £{{ item.recommendedPrice.toFixed(2) }}
                    </v-chip>
                  </template>
                  <template #item.retailPrice="{ item }">
                    <v-chip color="success" small>
                      £{{ item.retailPrice.toFixed(2) }}
                    </v-chip>
                  </template>
                  <template #item.totalCostPrice="{ item }">
                    <v-chip color="info" small>
                      £{{ item.totalCostPrice.toFixed(2) }}
                    </v-chip>
                  </template>
                  <template #item.totalRecommendedPrice="{ item }">
                    <v-chip color="primary" small>
                      £{{ item.totalRecommendedPrice.toFixed(2) }}
                    </v-chip>
                  </template>
                  <template #item.totalRetailPrice="{ item }">
                    <v-chip color="success" small>
                      £{{ item.totalRetailPrice.toFixed(2) }}
                    </v-chip>
                  </template>
                  <template #item.alerts="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-on="on"
                          v-bind="attrs"
                          small
                          color="danger"
                          v-show="item.recommendedPrice > item.retailPrice"
                        >
                          mdi-alert-outline
                        </v-icon>
                      </template>
                      <span> Our sell price is lower than the MSRP </span>
                    </v-tooltip>
                  </template>
                </trev-simple-table>
                <v-divider class="mb-4"></v-divider>
                <h6>Pro Rata Charges</h6>
                <trev-simple-table
                  v-if="item.proRataCharges.length > 0"
                  baseCardClasses="p-0"
                  cardTextClasses="py-0 px-0"
                  :headers="proRataHeaders"
                  :showTitleAndSearch="false"
                  :items="item.proRataCharges"
                  :pageSize="1000"
                  :showFooter="false"
                  :isDense="true"
                  :isLoading="false"
                  :hasLoaded="true"
                  :totalRecordCount="1000"
                >
                  <template #item.costPrice="{ item }">
                    <v-chip color="primary" small>
                      £{{ item.costPrice.toFixed(2) }}
                    </v-chip>
                  </template>
                  <template #item.retailPrice="{ item }">
                    <v-chip color="success" small>
                      £{{ item.retailPrice.toFixed(2) }}
                    </v-chip>
                  </template>
                </trev-simple-table>
                <p v-else class="text-center text-muted">
                  <v-divider class="mb-4"></v-divider>
                  There are no pro rata charges
                </p>
              </td>
            </template>
          </trev-cool-table-base>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog max-width="80vh" v-model="isShowingPricingModal" persistent>
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            <v-icon class="mr-2"> mdi-cash-multiple </v-icon>
            Update pricing for:
            <span class="primary--text">{{ selectedItem.companyName }}</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-list>
          <v-list-item
            v-for="(product, index) in selectedItem.products"
            :key="index"
            three-line
          >
            <v-list-item-action>
              <v-checkbox
                v-show="!isSavingPricingDetails && !hasSavedPricingDetails"
                v-model="product.toUpdate"
              ></v-checkbox>
              <v-progress-circular
                indeterminate
                v-show="isSavingPricingDetails && !hasSavedPricingDetails"
              ></v-progress-circular>
              <div v-show="!isSavingPricingDetails && hasSavedPricingDetails">
                <v-icon large class="success--text" v-show="!product.isInError">
                  mdi-check-circle-outline
                </v-icon>
                <v-icon large class="danger--text" v-show="product.isInError">
                  mdi-close-circle-outline
                </v-icon>
              </div>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-subtitle>
                Change Product:
                <span class="primary--text">{{ product.productName }}</span>
              </v-list-item-subtitle>
              <v-list-item-title>
                From
                <span class="info--text"
                  >£{{ product.retailPrice.toFixed(2) }}</span
                >
                to
                <span class="success--text"
                  >£{{ product.recommendedPrice.toFixed(2) }}</span
                >
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ product.updateMessage }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn
            text
            @click="cancelShowPricingModal"
            :disabled="isSavingPricingDetails"
            v-show="!hasSavedPricingDetails"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="savePricing"
            :loading="isSavingPricingDetails"
            v-show="!hasSavedPricingDetails"
          >
            <v-icon class="mr-2"> mdi-floppy </v-icon>
            Save changes
          </v-btn>
          <v-btn
            color="success"
            @click="cancelShowPricingModal"
            v-show="hasSavedPricingDetails"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isShowingInvoiceModal"
      max-width="100vh"
      @click:outside="cancelShowInvoice"
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            <v-icon class="mr-2"> mdi-chart-box-plus-outline </v-icon>
            Create Invoice
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :loading="isLoadingSendToXero"
            @click="sendInvoiceToXero"
            :disabled="hasSavedSendToXero"
          >
            <v-icon class="mr-2"> mdi-plus </v-icon>
            Create In Xero
          </v-btn>
        </v-toolbar>
        <template v-if="!hasSavedSendToXero">
          <trev-invoice-view
            v-if="invoice.date && invoice.dueDate"
            :canEdit="false"
            :companyId="selectedItemForInvoice.companyId"
            :showFormattedDates="true"
            :invoice="invoice"
          ></trev-invoice-view>
        </template>
        <template v-else>
          <v-container>
            <v-row>
              <v-col class="text-center">
                <v-icon large color="success"> mdi-check </v-icon>
                Sent to Xero
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="80vh"
      v-model="showAllInvoicesModal"
      persistent
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            <v-icon class="mr-2"> mdi-graph-outline </v-icon>
            Create Batch Invoices
          </v-toolbar-title>
          <v-spacer> </v-spacer>
          <v-btn color="success" @click="sendAllInvoicesToXero" :disabled="isFinishedAllInvoicingButton" :loading="allInvoicingItems.some(x => x.isLoading)">
            <v-icon class="mr-2"> mdi-plus </v-icon>
            Send all to Xero
          </v-btn>
          <v-btn class="ml-2" @click="cancelShowAllInvoices" v-show="isFinishedAllInvoicingButton">
            <v-icon class="mr-2">
              mdi-close-circle-outline
            </v-icon>
            Close
          </v-btn>
        </v-toolbar>
        <v-list>
          <v-list-item
            v-for="(invoiceItem, index) in allInvoicingItems"
            :key="index"
          >
            <v-list-item-action>
              <v-progress-circular indeterminate v-show="invoiceItem.isLoading">
              </v-progress-circular>
              <v-icon large color="success" v-show="invoiceItem.isComplete">
                mdi-check-circle-outline
              </v-icon>
              <v-icon
                large
                v-show="!invoiceItem.isComplete && !invoiceItem.isLoading"
              >
                mdi-autorenew
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ invoiceItem.companyName }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TrevInvoiceView from "../../../../components/invoices/trev-invoice-view.vue";
import TrevCoolTableBase from "../../../../components/tables/trev-cool-table-base.vue";
import trevSimpleTable from "../../../../components/tables/trev-simple-table.vue";

export default {
  components: { trevSimpleTable, TrevCoolTableBase, TrevInvoiceView },
  computed: {},
  created() {
    this.getData();
  },
  methods: {
    async showCreateAllInvoices() {
      this.isLoadingAllInvoicingButton = true;

      for (const item of this.items) {
        if (!item.xeroContactId || !item.xeroTenantId) {
          console.log('ignoring')
          continue;
        }
        var date = new Date();
        date.setMonth(date.getDate() + item.invoicingPeriod);
        date.setYear(date.getFullYear());

        var invoiceItem = {
          isLoading: false,
          isInError: false,
          isComplete: false,
          errors: [],
          companyId: item.companyId,
          companyName: item.companyName,
          products: item.products,
          invoice: {
            date: new Date(),
            dueDate: date,
            reference: "Microsoft Licenses",
            lineItems: [],
            tenantId: item.xeroTenantId,
            contactId: item.xeroContactId,
          },
        };

        for (const product of item.products) {
          invoiceItem.invoice.lineItems.push({
            description: product.productName,
            quantity: product.quantity,
            unitCost: product.retailPrice,
            accountCodeId: "8b5a698d-c2ce-430f-b7c5-7a71702aade7",
            accountCode: "225",
            accountCodeName: "Office 365 / Microsoft 365",
          });
        }

        for (const proRata of item.proRataCharges) {
          invoiceItem.invoice.lineItems.push({
            description: proRata.proRataName,
            quantity: 1,
            unitCost: proRata.retailPrice,
            accountCode: "225",
            accountCodeName: "Office 365 / Microsoft 365",
            accountCodeId: "8b5a698d-c2ce-430f-b7c5-7a71702aade7",
          });
        }

        this.allInvoicingItems.push(invoiceItem);
      }

      this.showAllInvoicesModal = true;
    },
    async sendAllInvoicesToXero() {
      //now generate the invoices / log them and add the line items / associate with the company
      var allInvoiceTasks = [];

      for (const invoiceItem of this.allInvoicingItems) {
        invoiceItem.isLoading = true;

        await new Promise(resolve => setTimeout(resolve, 2000));

        var addInvoiceToXeroTask = this.$courier.XeroInvoiceExternal.add(
          invoiceItem.invoice,
          false,
          [
            {
              key: "tenantId",
              value: invoiceItem.invoice.tenantId,
            },
          ]
        )
          .then((generatedInvoice) => {
            //add the invoice into trevor as a log
            var invoiceLogItem = {
              reference: generatedInvoice.reference,
              date: invoiceItem.invoice.date,
              dueDate: invoiceItem.invoice.dueDate,
              xeroTenantId: invoiceItem.invoice.tenantId,
              xeroContactId: generatedInvoice.contactId,
              xeroInvoiceId: generatedInvoice.id,
              xeroInvoiceNumber: generatedInvoice.invoiceNumber,
            };

            var innerInvoicingTasks = [];

            //add into company invoice log
            var addInvoiceToTrevorTask = this.$courier.Companies.withId(
              invoiceItem.companyId
            )
              .Invoices.add(invoiceLogItem)
              .then((newTrevorInvoice) => {
                for (var lineItem of invoiceItem.invoice.lineItems) {
                  var addInvoiceLineItemTask = this.$courier.Invoices.withId(
                    newTrevorInvoice.id
                  )
                    .LineItems.add({
                      accountCode: lineItem.accountCode,
                      accountCodeName: lineItem.accountCodeName,
                      description: lineItem.desciption,
                      quantity: lineItem.quantity,
                      unitCost: lineItem.unitCost,
                    })
                    .catch((error) => {
                      invoiceItem.errors.push(
                        "Error adding line item: " + error
                      );
                    });

                  innerInvoicingTasks.push(addInvoiceLineItemTask);
                }

                //associate the trevor invoice with each of the products

                for (const product of invoiceItem.products) {
                  var associateInvoiceToProductTask =
                    this.$courier.Products.AddInvoiceToProduct(
                      product.productId,
                      newTrevorInvoice.xeroInvoiceNumber
                    ).catch((error) => {
                      invoiceItem.errors.push(
                        "Error associating with product: " + error
                      );
                    });

                  innerInvoicingTasks.push(associateInvoiceToProductTask);
                }
              })
              .catch((error) => {
                invoiceItem.errors.push("Error adding log item: " + error);
              });

            innerInvoicingTasks.push(addInvoiceToTrevorTask);

            var finalInnerInvoicingTask = Promise.allSettled(
              innerInvoicingTasks
            ).then((results) => {
              if (results.every((x) => x.status == "fulfilled")) {
                invoiceItem.isComplete = true;
                invoiceItem.isLoading = false;
              }
            });

            allInvoiceTasks.push(finalInnerInvoicingTask);
          })
          .catch((error) => {
            invoiceItem.errors.push("Error sending invoice to Xero");
          });

        await addInvoiceToXeroTask;

        allInvoiceTasks.push(addInvoiceToXeroTask);

      }

      //once all of the invoicing tasks are finished set all to complete etc

      await Promise.allSettled(allInvoiceTasks);

      this.isLoadingAllInvoicingButton = false;
      this.isFinishedAllInvoicingButton = true;
    },
    cancelShowAllInvoices() {
      this.isLoadingAllInvoicingButton = false;
      this.isFinishedAllInvoicingButton = false;
      this.allInvoicingItems = [];
    },
    cancelShowInvoice() {
      this.selectedItemForInvoice = {};
      this.invoice = {};
      this.isShowingInvoiceModal = false;
      this.hasSavedSendToXero = false;
    },
    showInvoiceModal(item) {
      this.selectedItemForInvoice = item;
      this.invoice.date = new Date();

      var date = new Date();
      date.setMonth(date.getMonth());
      date.setDate(
        date.getDate() + this.selectedItemForInvoice.invoicingPeriod
      );
      date.setYear(date.getFullYear());

      this.invoice.dueDate = date;
      this.invoice.reference = "Microsoft Licenses";

      this.invoice.lineItems = [];

      for (const product of this.selectedItemForInvoice.products) {
        this.invoice.lineItems.push({
          description: product.productName,
          quantity: product.quantity,
          unitCost: product.retailPrice,
          accountCodeId: "8b5a698d-c2ce-430f-b7c5-7a71702aade7",
          accountCode: "225",
          accountCodeName: "Office 365 / Microsoft 365",
        });
      }

      for (const proRata of this.selectedItemForInvoice.proRataCharges) {
        this.invoice.lineItems.push({
          description: proRata.proRataName,
          quantity: 1,
          unitCost: proRata.retailPrice,
          accountCode: "225",
          accountCodeName: "Office 365 / Microsoft 365",
          accountCodeId: "8b5a698d-c2ce-430f-b7c5-7a71702aade7",
        });
      }

      this.isShowingInvoiceModal = true;
    },
    async sendInvoiceToXero() {
      this.isLoadingSendToXero = true;
      this.hasSavedSendToXero = false;

      var generatedInvoice = await this.$courier.XeroInvoiceExternal.add(
        this.invoice,
        false,
        [{ key: "tenantId", value: this.invoice.tenantId }]
      );

      this.isLoadingSendToXero = false;
      this.hasSavedSendToXero = true;

      //log the invoice

      var invoiceLogItem = {
        reference: generatedInvoice.reference,
        date: this.invoice.date,
        dueDate: this.invoice.dueDate,
        xeroTenantId: this.invoice.tenantId,
        xeroContactId: generatedInvoice.contactId,
        xeroInvoiceId: generatedInvoice.id,
        xeroInvoiceNumber: generatedInvoice.invoiceNumber,
      };

      this.$courier.Companies.withId(this.selectedItemForInvoice.companyId)
        .Invoices.add(invoiceLogItem)
        .then((newInvoice) => {
          //save the invoice against the product

          for (const product of this.selectedItemForInvoice.products) {
            this.$courier.Products.AddInvoiceToProduct(
              product.productId,
              generatedInvoice.invoiceNumber
            )
              .then(() => {
                //idk do somethng
              })
              .catch((error) => {
                console.log(error);
                console.log(
                  "There was a problem associating invoice with product"
                );
              });
          }

          for (var lineItem of this.invoice.lineItems) {
            var newLineItem = {
              accountCode: lineItem.accountCode,
              accountCodeName: lineItem.accountCodeName,
              description: lineItem.description,
              quantity: lineItem.quantity,
              unitCost: lineItem.unitCost,
            };

            this.$courier.Invoices.withId(newInvoice.id)
              .LineItems.add(newLineItem)
              .then((newLineItem) => {
                //idk what to do here with the ne wline item
              });
          }
        });
    },
    savePricing() {
      this.isSavingPricingDetails = true;
      var productsToUpdate = this.selectedItem.products.filter(
        (x) => x.toUpdate
      );

      this.selectedItem.products = productsToUpdate;

      if (!productsToUpdate || productsToUpdate.length == 0) {
        this.cancelShowPricingModal();
      }

      var allTasks = [];

      for (const product of productsToUpdate) {
        product.updateMessage = "Updating...";
        var task = this.$courier.Products.UpdatePriceInPax(
          product.productId,
          product.recommendedPrice
        )
          .then(() => {
            product.updateMessage = "Saved Price";
          })
          .catch((error) => {
            error.json().then((errorAsJson) => {
              product.updateMessage =
                "There was an error saving: " + errorAsJson.message;
              product.isInError = true;
            });
          });

        allTasks.push(task);
      }

      Promise.allSettled(allTasks).then(() => {
        this.hasSavedPricingDetails = true;
        this.isSavingPricingDetails = false;
      });
    },
    cancelShowPricingModal() {
      this.isShowingPricingModal = false;
      this.selectedItem = {};

      setTimeout(() => {
        this.hasSavedPricingDetails = false;
        this.isSavingPricingDetails = false;
        this.getData();
      }, 200);
    },
    showPricingModal(item) {
      var newItem = JSON.parse(JSON.stringify(item));
      newItem.products.forEach((product) => {
        product.toUpdate = true;
        product.hasUpdated = false;
        product.updateMessage = "";
        product.isInError = false;
      });

      this.selectedItem = newItem;
      this.isShowingPricingModal = true;
    },
    getData() {
      this.isLoading = true;
      this.$courier.Reports.CompanyMicrosoftProducts().then((resp) => {
        this.items = resp.payload;
        this.totalCount = resp.totalCount;
        this.isLoading = false;
      });
    },
  },
  data() {
    return {
      isLoadingAllInvoicingButton: false,
      isFinishedAllInvoicingButton: false,
      showAllInvoicesModal: false,
      allInvoicingItems: [],
      isLoadingSendToXero: false,
      hasSavedSendToXero: false,
      invoice: {},
      selectedItemForInvoice: {},
      isShowingInvoiceModal: false,
      hasSavedPricingDetails: false,
      isSavingPricingDetails: false,
      selectedItem: {},
      isShowingPricingModal: false,
      isLoading: true,
      items: [],
      totalCount: 0,
      headers: [
        {
          text: "Company",
          value: "companyName",
        },
        {
          text: "(£) Total MSRP",
          value: "totalRecommendedPrice",
        },
        {
          text: "(£) Total Partner",
          value: "totalCostPrice",
        },
        {
          text: "(£) Total Price",
          value: "totalRetailPrice",
        },
        {
          text: "Profit",
          value: "profit",
        },
        {
          text: "Alerts",
          value: "alerts",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
      productHeaders: [
        {
          text: "Name",
          value: "productName",
        },
        {
          text: "Quantity",
          value: "quantity",
        },
        {
          text: "Buy Price (Per Unit)",
          value: "costPrice",
        },
        {
          text: "MSRP (Per Unit)",
          value: "recommendedPrice",
        },
        {
          text: "Sell Price (Per Unit)",
          value: "retailPrice",
        },
        {
          text: "Total Cost Price",
          value: "totalCostPrice",
        },
        {
          text: "Total MSRP Price",
          value: "totalRecommendedPrice",
        },
        {
          text: "Total Price",
          value: "totalRetailPrice",
        },
        {
          text: "Alerts",
          value: "alerts",
        },
      ],
      proRataHeaders: [
        {
          text: "Name",
          value: "proRataName",
        },
        {
          text: "Cost Price",
          value: "costPrice",
        },
        {
          text: "Our Price",
          value: "retailPrice",
        },
      ],
    };
  },
};
</script>

<style>
</style>